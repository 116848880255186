import React from 'react';
import { Link } from 'gatsby';

const ArticlePreview = ({ title, url, date, excerpt }) => (
  <div className="mb-8 sm:mb-16 md:mb-32">
    <h2 className="text-3xl sm:text-4xl md:text-5xl font-black leading-none mb-4">
      <Link to={url} className="">{title}</Link>
    </h2>

    <div className="" dangerouslySetInnerHTML={{ __html: excerpt }} />
  </div>
);

export default ArticlePreview;